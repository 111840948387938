import React, { useContext } from 'react'
import { Entity } from "@parallelpublicworks/entitysync";
import { ImpactContext } from '../../components/Layout'
import ProfileForm from '../../components/ProfileForm'
import { CircularProgress } from '../../components/library'

function Child() {
    const { user } = useContext(ImpactContext)

    if(!user) return <CircularProgress />

    return (
        <Entity type="user--user" source={user} componentId="child-entity">
            <ProfileForm member="child" label_prefix="Child's" />
        </Entity>
    )
}

export default Child
